<template>
  <b-container fluid>
    <b-row>
      <b-col md="12" class="heading_alignment">
        <iq-card style="padding-left:20px; padding-right:20px">
          <template v-slot:headerTitle>
            <div v-if="userData.user_role === 'USERROLE11111'">
              <h4 class="card-title" >
              <i class="fa-regular fa-chart-bar" aria-hidden="true" style="font-size: 21px; margin-right: 10px; color: var(--iq-primary);"></i>
              {{ cvCardTitle }}
              </h4>
            </div>
            <div v-else-if="userData.user_role === 'USERROLE11115'">
              <h4 class="card-title" >
              {{ cvCardTitle }} : {{ itemName }}
            </h4>
              <u style="cursor:pointer;ma">
                <span @click="showPlanDetail(planName)"><b>View Plan Detials</b></span>
              </u>
              <p>Module Name : Univ</p>
            </div>
            <div v-else-if="userData.user_role === 'USERROLE11116'">
              <h4 class="card-title" >
              {{ cvCardTitle }} : {{ itemName }}
            </h4>
              <u style="cursor:pointer;ma">
                <span @click="showPlanDetail(planName)"><b>View Plan Detials</b></span>
              </u>
              <p>Module Name : Org</p>
            </div>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="4" xs="12" sm="6" class="mb-3">
                <b-form-input v-model="filter" type="search" :placeholder="cvSearchText"></b-form-input>
              </b-col>
              <b-col md="8" sm="6" xs="12" class="mb-3">
                <div class="dateRange">
                  <date-range-picker ref="picker" :show-dropdowns="true" class="w-100" :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                    v-model="whereFilter.dateRange" @update="subscriptionReportDateWise()"></date-range-picker>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="subReport && subReport.length > 0">
                <b-table hover responsive
                  :items="subReport"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage">
                  <template v-slot:cell(item_name)="data" >
                    <p style="word-wrap: break-word" v-if="data.item.item_name">{{ data.item.item_name }} - {{data.item.user_id  }}</p>
                    <p style="word-wrap: break-word" v-else>{{ data.item.org_name }} - {{data.item.user_id  }}</p>
                  </template>
                  <template v-slot:cell(item_email)="data" >
                    <p style="word-wrap: break-word" v-if="data.item.item_email">{{ data.item.item_email }}</p>
                    <p style="word-wrap: break-word" v-else>{{ data.item.org_admin_email }}</p>
                  </template>
                  <template v-slot:cell(subs_plan_name)="data" >
                   <p style="word-wrap: break-word"> {{ data.item.subs_plan_name }} - {{  data.item.subs_id }} </p>
                  </template>
                  <template v-slot:cell(validity_starts)="data" >
                    {{data.item.validity_starts | dateFormatDDMMYYYYHHMMA}}
                  </template>
                  <template v-slot:cell(validity_end)="data">
                    {{data.item.validity_end | dateFormatDDMMYYYYHHMMA}}
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1">
              </b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm"
                  class="my-0">
                </b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
        <!-- PlanDetail -->
      <PlanDetail :propShowPDModal="propShowPDModal" :propSubsPlanDetails="propSubsPlanDetails" @emitClosePDModal="emitClosePDModal" />
  </b-container>
</template>
<script>
import "vue2-daterange-picker/dist/vue2-daterange-picker.css"
import { Subscriptions } from "../../../FackApi/api/Subscription/subscription"
import { socialvue } from "../../../config/pluginInit"
import PlanDetail from "../../../components/PlanDetail.vue"
import userPermission from "../../../Utils/user_permission.js"

export default {
  name: "University_subscription_report",
  components: {
    PlanDetail
  },
  data () {
    return {
      apiName: "subscription_report",
      cvCardTitle: "Subscription Report",
      subReport: null,
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Organisation Subscription Report Response",
      sortBy: "name",
      sortDesc: false,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 14,
      cvSearchText: "Type to Search",
      organisationName: "",
      propShowPDModal: false,
      itemName: "",
      columns: [],
      propSubsPlanDetails: {},
      planName: null,
      whereFilter: {
        dateRange: {
          startDate: new Date(new Date().setDate(new Date().getDate() - 365)), // From Last Year
          endDate: new Date(new Date().setDate(new Date().getDate() + 1))
        }
      }
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  async mounted () {
    socialvue.index()
    let self = this

    switch (this.userData.user_role) {
      case "USERROLE11111":
      case "USERROLE11118":
        self.columns = [
          { label: "# ", key: "id", class: "text-left align-text-top text-wrap ", sortable: true },
          { label: "Organization", key: "item_name", class: "text-left align-text-top text-wrap ", sortable: true },
          { label: "Email", key: "item_email", class: "text-left align-text-top text-wrap w-260px", sortable: true },
          { label: "Subscription Plan", key: "subs_plan_name", class: "text-left align-text-top text-wrap ", sortable: true },
          { label: "Module", key: "mod_name", class: "text-left align-text-top text-wrap ", sortable: true },
          { label: "Module type", key: "sub_mod_name", class: "text-left align-text-top text-wrap ", sortable: true },
          { label: "Current Cap Value", key: "curr_cap_value", class: "text-left align-text-top text-wrap ", sortable: true },
          { label: "Allocated Cap Value", key: "allocated_cap_value", class: "text-left align-text-top ", sortable: true },
          { label: "Validity", key: "validity", class: "text-left align-text-top ", sortable: true },
          { label: "Validity Start", key: "validity_starts", class: "text-left align-text-top text-wrap ", sortable: true },
          { label: "Validity End", key: "validity_end", class: "text-left align-text-top ", sortable: true }
        ]
        break
      case "USERROLE11115":
        // Univ Admin
        this.cvCardTitle = "Subscription Report"
        self.columns = [
          { label: "# ", key: "id", class: "text-left align-text-top text-wrap ", sortable: true },
          { label: "Subscription Plan", key: "subs_plan_name", class: "text-left align-text-top text-wrap ", sortable: true },
          { label: "Module", key: "mod_name", class: "text-left align-text-top text-wrap ", sortable: true },
          { label: "Module type", key: "sub_mod_name", class: "text-left align-text-top text-wrap ", sortable: true },
          { label: "Current Cap Value", key: "curr_cap_value", class: "text-left align-text-top text-wrap ", sortable: true },
          { label: "Allocated Cap Value", key: "allocated_cap_value", class: "text-left align-text-top ", sortable: true },
          { label: "Validity", key: "validity", class: "text-left align-text-top ", sortable: true },
          { label: "Validity Start", key: "validity_starts", class: "text-left align-text-top text-wrap ", sortable: true },
          { label: "Validity End", key: "validity_end", class: "text-left align-text-top ", sortable: true }
        ]
        break
      case "USERROLE11116":
        // Org Admin
        this.cvCardTitle = "Subscription Report"
        self.columns = [
          { label: "# ", key: "id", class: "text-left align-text-top text-wrap ", sortable: true },
          { label: "Subscription Plan", key: "subs_plan_name", class: "text-left align-text-top text-wrap ", sortable: true },
          { label: "Module", key: "mod_name", class: "text-left align-text-top text-wrap ", sortable: true },
          { label: "Module type", key: "sub_mod_name", class: "text-left align-text-top text-wrap ", sortable: true },
          { label: "Current Cap Value", key: "curr_cap_value", class: "text-left align-text-top text-wrap ", sortable: true },
          { label: "Allocated Cap Value", key: "allocated_cap_value", class: "text-left align-text-top ", sortable: true },
          { label: "Validity", key: "validity", class: "text-left align-text-top ", sortable: true },
          { label: "Validity Start", key: "validity_starts", class: "text-left align-text-top text-wrap ", sortable: true },
          { label: "Validity End", key: "validity_end", class: "text-left align-text-top ", sortable: true }
        ]
        break
      default:
        break
    }

    await this.getDateFilter()
    this.subscriptionReport()
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    dateFilter () {
      return { ...this.$store.getters["DateFilter/selectedDateState"] }
    }
  },
  methods: {
    /**
     * subscription Report Datewise
     */
    async subscriptionReportDateWise () {
      this.subscriptionReport()
      await this.setDateFilter()
    },
    /**
     * goToSubscription
     */
    goToSubscription () {
      /*eslint-disable */
      this.$router.push(`/subscription_plans`)
    },
    /**
     * Get daterange from LS. If found then use this as default else use the onse set in the data section of the object
     */
    async getDateFilter () {
      let storeDate = await this.dateFilter
      if (storeDate && storeDate.dateRange) {
        this.whereFilter = storeDate
      }
    },
    /**
     * Setting Date Range in the LS
     */
    async setDateFilter () {
      let endDate = this.whereFilter.dateRange.endDate
      this.whereFilter.dateRange.endDate = new Date(new Date(endDate).setDate(new Date(endDate).getDate() + 1))
      let whereDateFilter = { dateRange: this.whereFilter.dateRange }
      await this.$store.commit("DateFilter/addSelectedDate", whereDateFilter)
    },
    /**
     * subscriptionReport
     */
    async subscriptionReport () {
      try {
        let payload = {}
        console.log("userData", this.userData);
        if (this.userData.user_role === "USERROLE11115" &&
          this.userData.modules.organisations &&
          Object.keys(this.userData.modules.organisations).length > 0) {
          payload.module_obj_id = Object.keys(this.userData.modules.organisations)[0]
          payload.module_name = "UNIV"
        }
        else if (this.userData.user_role === "USERROLE11116" &&
          this.userData.modules.organisations &&
          Object.keys(this.userData.modules.organisations).length > 0) {
          payload.module_obj_id = Object.keys(this.userData.modules.organisations)[0]
          payload.module_name = "ORG"
        }

        // subscriptionreportlist
        let subReportList = await Subscriptions.subscriptionReport(this, payload, this.whereFilter)
        if (subReportList.resp_status) {
          this.subReport = subReportList.resp_data 
          this.totalRows = subReportList.resp_data_count
          this.planName = subReportList.resp_data[0]
          this.itemName = subReportList.resp_data[0].item_name
        }
        else {
          this.toastMsg = subReportList.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at subscriptionReport() and Exception:", err.message)
      }
    },
    /**
    *show Plan detail
     */
    async showPlanDetail (plan) {
      this.propShowPDModal = Math.random()
      this.propSubsPlanDetails = plan
    },
        /**
     * emitCloseSCModal
     */
     emitClosePDModal () {
      this.propShowSCModal = false
      this.propSubsPlanDetails = {}
    }
  }
}
</script>
